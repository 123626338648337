<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="회의기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.proceedingsDate }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus(props.row.approvalStatusCd)]">
                    {{ props.row.approvalStatusName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.proceedingsTitle }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.regName }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mobileConfig from '@/js/mobileConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'proceedings',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'proceedingsTitle',
            field: 'proceedingsTitle',
            label: '회의제목',
            align: 'left',
            type: "link",
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'proceedingsDate',
            field: 'proceedingsDate',
            label: '회의일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'regName',
            field: 'regName',
            label: '작성자/작성일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
        attendeeUserId: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      validUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.osh.proceedings.list.url;
      this.validUrl = mobileConfig.sai.proceeding.valid.url;
      // code setting
      if (this.$route.query.proceedingsId) {
        this.goSigPopup();
      }
      this.searchParam.attendeeUserId = this.$store.getters.user.userId
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    goSigPopup() {
      this.$http.url = this.validUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        proceedingsId: this.$route.query.proceedingsId,
        attendeeUserId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.popupOptions.title = '산안위 회의록참석자 서명하기';
          this.popupOptions.param = {
            proceedingsId: this.$route.query.proceedingsId
          };
          this.popupOptions.target = () => import(`${'./signaturePop.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '회사측 혹은 근로자측 참석자가 아닙니다.', 
            type: 'warning', // success / info / warning / error
          });
        }
      },);
    },
    linkClick(row) {
      this.popupOptions.target = () => import(`${"./proceedingsDetail.vue"}`);
      this.popupOptions.title = '산안위 회의록 상세';
      this.popupOptions.param = {
        proceedingsId: row ? row.proceedingsId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'ASC0000000': // 결재요청
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'ASC0000001': // 결재중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'ASC0000002': // 반려
          cls = 'txt-box-grid text-red-box';
          break;
        case 'ASC9999999': // 결재완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
  }
};
</script>
